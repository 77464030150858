import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';
import refreshToken from '../api/authHelper';
import logo from '../SC_logo.svg'; 
import { jwtDecode } from 'jwt-decode';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './style/Core.css';
import UpcomingMatches from './UpcomingMatches';
import DownloadData from './DownloadData';
import VisualizationDashboard from './visualizations/Dashboard';
import GenerateAPIKey from './GenerateAPIKey';
import UserRBACManagement from './UserManagement';
import PlayerSearch from './PlayerSearch';
import { default as MatchVideo } from './matchvideo/Main';
import { usePermissions } from '../context/PermissionsContext';

if (process.env.REACT_APP_BUILD_ENV === 'production ') {
  console.log = function () { };
}

const getTokenExpirationDate = (token) => {
    if (!token || typeof token !== 'string') {
        toast.error('Invalid token provided');
        return null;
    }
    const decodedToken = jwtDecode(token);
    if (!decodedToken.exp) {
        return null;
    }
    const date = new Date(0);
    date.setUTCSeconds(decodedToken.exp);
    return date;
};

const isTokenExpired = (token) => {
    try {
        const expirationDate = getTokenExpirationDate(token);
        return expirationDate < new Date();
    } catch (error) {
        toast.error('Token check failed');
        console.error('Token check failed:', error);
        return true; // Treat invalid token as expired
    }
};

const Dashboard = () => {
  const { user, setUser, logout } = useAuth();
  const navigate = useNavigate();
  const [currentComponent, setCurrentComponent] = useState('default');

  const { roles, permissions, meta } = usePermissions();

  const canViewStats = permissions.some(permission => permission.app_label === 'stats' && permission.model === 'upcomingmatchstats' && permission.can_view);
  const canViewMatchVideo = permissions.some(permission => permission.app_label === 'matches' && permission.model === 'matchvideo' && permission.can_view);
  const isAnalyst = roles.some(role => typeof role === 'string' && role.toLowerCase() === 'analyst');
  const isSuperUser = meta.is_superuser;

  console.log('Roles:', roles);
  const checkTokenExpiry = useCallback(() => {
    if (user && user.accessToken) {
      if (isTokenExpired(user.accessToken)) {
        try {
          const tokens = refreshToken(user.refreshToken);
          setUser({ ...user, accessToken: tokens.accessToken, refreshToken: tokens.refreshToken });
        } catch (error) {
          toast.error('Failed to refresh token. Logging out.');
          logout();
          navigate('/login');
        }
      } 
    } else {
      toast.error('No user or token found. Logging out.');
      logout();
      navigate('/login');
    }
  }, [user, setUser, logout, navigate]);

  useEffect(() => {
    if (!user) {
      console.log('No user found. Redirecting to login.');
      navigate('/login');
      return;
    }

    const intervalId = setInterval(checkTokenExpiry, 60000); // Check every minute
    return () => {
      clearInterval(intervalId); // Cleanup on unmount
    };
  }, [user, checkTokenExpiry, navigate]);

  const handleMenuSelection = (component) => {
    setCurrentComponent(component);
  };

  const renderContent = () => {
    if (roles.length === 0 && !isSuperUser) {
      return <div>Please contact your manager for permission to access this resource.</div>;
    }

    switch (currentComponent) {
      case 'UpcomingMatches':
        return <UpcomingMatches />;
      case 'DownloadData':
        return <DownloadData />;
      case 'VisualizationDashboard':
        return <VisualizationDashboard />;
      case 'GenerateAPIKey':  
        return <GenerateAPIKey />;
      case 'UserRBACManagement':
        return <UserRBACManagement />;
      case 'MatchVideo':
        return <MatchVideo />;
      case 'PlayerSearch':
        return <PlayerSearch />;
      default:
        return <div>Select a component from the sidebar.</div>;
    }
  };

  const styles = {
    dashboard: {
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',  // This makes sure the dashboard takes full viewport height
        overflow: 'hidden', // Prevent scrolling on the dashboard
    },
    header: {
        backgroundColor: '#0E1426',
        color: 'white',
        padding: '10px 20px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        width: '100%',
        position: 'fixed',  // This will fix the header at the top
        top: 0,  // Top edge alignment
        left: 0,  // Left edge alignment
        right: 0,  // Stretch across to the right edge
        height: '70px', // Increase header height
        zIndex: 1000,  // Ensures header is above other content
    },
    logo: {
        height: '50px',
    },
    body: {
        display: 'flex',
        flex: 1,
        marginTop: '70px',  // Offset by the height of the header
        height: 'calc(100vh - 70px)',  // Adjust height to account for the header
    },
    sidebar: {
        width: '200px',
        padding: '20px',
        color: 'white',
        height: 'calc(100vh - 70px)',  // Make sidebar height take up remaining viewport height minus header
        position: 'fixed',  // Fix the sidebar position
        top: '70px',  // Position below the header
        overflowY: 'auto', // Allow sidebar scrolling if content overflows
    },
    content: {
        flex: 1,
        overflowY: 'auto',  // Only the content will scroll
        color: '#BBCCD5',
        paddingTop: '20px', 
        marginLeft: '220px',  // Offset by the width of the sidebar
        height: 'calc(100vh - 70px)',  // Adjust height to fit in the viewport below the header
    },
    button: {
        backgroundColor: '#80CFFF',
        color: '#0E1426',
        padding: '10px 20px',
        marginBottom: '10px',
        height: '35px',
        width: '160px',
        border: 'none',
        borderRadius: '5px',
        cursor: 'pointer',
    }
};

  return (
    <div style={styles.dashboard}>
      <header style={styles.header}>
        <img src={logo} alt="ShortCircuit Logo" style={styles.logo} />
      </header>
      <div style={styles.body}>
      <aside style={styles.sidebar}>
        { (canViewStats || isSuperUser ) && <button style={styles.button} onClick={() => handleMenuSelection('UpcomingMatches')}>Upcoming Matches</button>}
        { (canViewMatchVideo || isSuperUser ) && <button style={styles.button} onClick={() => handleMenuSelection('MatchVideo')}>Match Video</button>}
        {isSuperUser && <button style={styles.button} onClick={() => handleMenuSelection('DownloadData')}>Get Raw Data</button>}
        { (isAnalyst || isSuperUser ) && <button style={styles.button} onClick={() => handleMenuSelection('VisualizationDashboard')}>Visualizations</button> }
        {isSuperUser && <button style={styles.button} onClick={() => handleMenuSelection('GenerateAPIKey')}>API Key</button>}
        {isSuperUser && <button style={styles.button} onClick={() => handleMenuSelection('UserRBACManagement')}>User Management</button>}
        { (isAnalyst || isSuperUser ) && <button style={styles.button} onClick={() => handleMenuSelection('PlayerSearch')}>Physical Data</button>}
        <button className="logoutButton" onClick={logout} style={{...styles.button, position: 'absolute', bottom: '40px', left: '20px'}}>
          Logout
        </button>
      </aside>
        <main style={styles.content}>
          {renderContent()}
        </main>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Dashboard;
