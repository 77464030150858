import React, { useState, useEffect, useMemo } from 'react';
import axiosInstance from '../api/axiosInstance';
import Fuse from 'fuse.js';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';

// Register Chart.js components
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

const PlayerSearchAutocomplete = () => {
  // Search and player list state
  const [players, setPlayers] = useState([]);
  const [filteredPlayers, setFilteredPlayers] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [loading, setLoading] = useState(true);

  // Selected player and API response state
  const [selectedPlayer, setSelectedPlayer] = useState(null);
  const [playerPhysicalData, setPlayerPhysicalData] = useState(null);
  const [loadingPhysicalData, setLoadingPhysicalData] = useState(false);

  // Selector states
  const [selectedAttribute, setSelectedAttribute] = useState('total_distance');
  const [measureType, setMeasureType] = useState("per90"); // "per90" or "actual"
  const [comparison, setComparison] = useState("Role"); // "Role" (default) or "All"

  const LOCAL_STORAGE_KEY = 'playersCache';
  const FOUR_HOURS = useMemo(() => 4 * 60 * 60 * 1000, []);

  // Helper function to compute rolling average with a specified window size
  const computeRollingAverage = (data, windowSize) => {
    const result = [];
    for (let i = 0; i < data.length; i++) {
      const start = Math.max(0, i - windowSize + 1);
      const windowData = data.slice(start, i + 1);
      const sum = windowData.reduce((acc, curr) => acc + curr, 0);
      result.push(sum / windowData.length);
    }
    return result;
  };

  // Fetch player list from localStorage or API
  useEffect(() => {
    const cached = localStorage.getItem(LOCAL_STORAGE_KEY);
    if (cached) {
      try {
        const parsed = JSON.parse(cached);
        if (Date.now() < parsed.expiration) {
          setPlayers(parsed.data);
          setLoading(false);
          return;
        } else {
          localStorage.removeItem(LOCAL_STORAGE_KEY);
        }
      } catch (err) {
        console.error('Error parsing cached data', err);
        localStorage.removeItem(LOCAL_STORAGE_KEY);
      }
    }

    const fetchPlayers = async () => {
      try {
        const response = await axiosInstance.get('/stats/playersearchhelper/', { timeout: 600000 });
        setPlayers(response.data);
        localStorage.setItem(
          LOCAL_STORAGE_KEY,
          JSON.stringify({ expiration: Date.now() + FOUR_HOURS, data: response.data })
        );
      } catch (error) {
        console.error('Error fetching player data:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchPlayers();
  }, [FOUR_HOURS]);

  // Set up Fuse for fuzzy search (using the field 'search_name')
  const fuse = useMemo(
    () =>
      new Fuse(players, {
        keys: ['search_name'],
        threshold: 0.3,
        ignoreLocation: true,
        shouldSort: true,
      }),
    [players]
  );

  useEffect(() => {
    if (searchQuery.trim().length >= 3) {
      const results = fuse.search(searchQuery);
      setFilteredPlayers(results.map((result) => result.item));
    } else {
      setFilteredPlayers([]);
    }
  }, [searchQuery, fuse]);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleSelectPlayer = async (player) => {
    setSelectedPlayer(player);
    setSearchQuery('');
    setFilteredPlayers([]);
    setPlayerPhysicalData(null);
    setLoadingPhysicalData(true);

    try {
      // Fetch the player's full data including matches and averages
      const response = await axiosInstance.get(`/stats/physicaldata/${player.wyId}/`);
      setPlayerPhysicalData(response.data);
    } catch (error) {
      console.error('Error fetching player physical data:', error);
      setPlayerPhysicalData({ error: 'Failed to load data' });
    } finally {
      setLoadingPhysicalData(false);
    }
  };

  // Inline styles
  const containerStyle = { padding: '20px', fontFamily: 'Arial, sans-serif' };
  const inputStyle = { padding: '10px', width: '300px', fontSize: '16px' };
  const dropdownStyle = {
    position: 'absolute',
    width: '300px',
    maxHeight: '200px',
    overflowY: 'auto',
    border: '1px solid #ddd',
    borderTop: 'none',
    backgroundColor: 'white',
    zIndex: 1000,
  };
  const dropdownItemStyle = { padding: '10px', borderBottom: '1px solid #eee', cursor: 'pointer', color: '#000' };
  const selectedStyle = { marginTop: '20px', padding: '10px' };

  // Styles for the player info card
  const cardStyle = {
    display: 'flex',
    alignItems: 'center',
    border: '1px solid #ddd',
    borderRadius: '8px',
    padding: '15px',
    marginBottom: '20px',
    boxShadow: '2px 2px 5px rgba(0,0,0,0.1)',
  };
  const cardImageStyle = {
    width: '100px',
    height: '100px',
    borderRadius: '50%',
    objectFit: 'cover',
    marginRight: '20px',
  };
  const cardInfoStyle = { display: 'flex', flexDirection: 'column' };

  // Options for attributes to graph
  const attributeOptions = [
    { value: 'total_distance', label: 'Total Distance (Meters)' },
    { value: 'run_distance', label: 'Running Distance (Meters)' },
    { value: 'sprint_distance', label: 'Sprint Distance (Meters)' },
    { value: 'hi_distance', label: 'High Intensity Distance (Meters)' },
    { value: 'hsr_distance', label: 'High Speed Running Distance (Meters)' },
    { value: 'medium_accelerations', label: 'Medium Accelerations (Count)' },
    { value: 'medium_decelerations', label: 'Medium Decelerations (Count)' },
    { value: 'high_accelerations', label: 'High Accelerations (Count)' },
    { value: 'high_decelerations', label: 'High Decelerations (Count)' },
    { value: 'max_speed', label: 'Max Speed (km/h)' },
    { value: 'meters_per_minute', label: 'Meters per Minute' },
    { value: 'high_speed_runs', label: 'High Speed Runs (Count)' },
    { value: 'sprints', label: 'Sprints (Count)' },
  ];

  // Options for measure type selector
  const measureOptions = [
    { value: 'per90', label: 'Per 90' },
    { value: 'actual', label: 'Actual' },
  ];

  // Options for Comparison selector
  const comparisonOptions = [
    { value: 'Role', label: 'Role' },
    { value: 'All', label: 'All' },
  ];

  if (loading) {
    return (
      <div style={containerStyle}>
        <h2>Player Physical Data Search</h2>
        <div style={{ fontFamily: 'monospace', fontSize: '24px' }}>Loading...</div>
      </div>
    );
  }

  // Prepare chart data if API response is available and contains match data
  let chartSection = null;
  if (playerPhysicalData && playerPhysicalData.matches) {
    // Sort matches from oldest to newest
    const sortedMatches = [...playerPhysicalData.matches].sort(
      (a, b) => new Date(a.match.date) - new Date(b.match.date)
    );
    // X-axis labels: only the date
    const labels = sortedMatches.map((m) => new Date(m.match.date).toLocaleDateString());

    // Calculate player's performance per match (with per90 conversion if needed)
    const playerData = sortedMatches.map((m) => {
      let stat = m[selectedAttribute];
      const minutes = m.minutes ?? 0;
      if (measureType === "per90" && !["max_speed", "meters_per_minute"].includes(selectedAttribute)) {
        return minutes > 0 ? (stat / minutes) * 90 : 0;
      }
      return stat;
    });

    // Compute the rolling average (trend) with a window of 3 matches
    const trendData = computeRollingAverage(playerData, 3);

    // Determine overall average based on Comparison selector
    const overallAvgRaw =
      comparison === "Role"
        ? playerPhysicalData.role_averages[selectedAttribute]
        : playerPhysicalData.overall_averages[selectedAttribute];
    const overallAvgMinutes =
      comparison === "Role"
        ? playerPhysicalData.role_averages.minutes
        : playerPhysicalData.overall_averages.minutes;
    const overallData = sortedMatches.map(() => {
      if (measureType === "per90" && !["max_speed", "meters_per_minute"].includes(selectedAttribute)) {
        return overallAvgMinutes > 0 ? (overallAvgRaw / overallAvgMinutes) * 90 : 0;
      }
      return overallAvgRaw;
    });

    // For season averages, choose role averages if Comparison === "Role"
    const seasonData = sortedMatches.map((m) => {
      let raw, minutes;
      if (comparison === "Role" && m.season_averages.role) {
        raw = m.season_averages.role[selectedAttribute];
        minutes = m.season_averages.role.minutes;
      } else {
        raw = m.season_averages[selectedAttribute];
        minutes = m.season_averages.minutes;
      }
      if (measureType === "per90" && !["max_speed", "meters_per_minute"].includes(selectedAttribute)) {
        return minutes && minutes > 0 ? (raw / minutes) * 90 : 0;
      }
      return raw;
    });

    const chartData = {
      labels,
      datasets: [
        {
          label: 'Player Performance',
          data: playerData,
          borderColor: 'rgba(0, 255, 0, 1)',
          backgroundColor: 'rgba(0, 255, 0, 0.2)',
          tension: 0.2,
          fill: false,
        },
        {
          label: 'Season Average',
          data: seasonData,
          borderColor: 'rgba(153,102,255,1)',
          backgroundColor: 'rgba(153,102,255,0.2)',
          tension: 0.2,
          fill: false,
        },
        {
          label: 'Overall Average',
          data: overallData,
          borderColor: 'rgba(255,159,64,1)',
          backgroundColor: 'rgba(255,159,64,0.2)',
          borderDash: [5, 5],
          tension: 0.2,
          fill: false,
        },
        {
          label: 'Trend',
          data: trendData,
          borderColor: 'rgba(75, 192, 192, 1)',
          backgroundColor: 'rgba(75, 192, 192, 0.2)',
          tension: 0.2,
          fill: false,
        },
      ],
    };

    const options = {
      responsive: true,
      plugins: {
        tooltip: {
          callbacks: {
            // Tooltip title shows full date
            title: (tooltipItems) => {
              const idx = tooltipItems[0].dataIndex;
              const match = sortedMatches[idx].match;
              return new Date(match.date).toLocaleDateString();
            },
            // Tooltip label adds additional details
            label: (tooltipItem) => {
              const idx = tooltipItem.dataIndex;
              const match = sortedMatches[idx].match;
              return [
                `Match: ${match.label}`,
                `Competition: ${match.competition.name}`,
                `Season: ${match.season.name}`,
                `Minutes: ${sortedMatches[idx].minutes || 0}`,
              ];
            },
          },
        },
        legend: { position: 'top' },
        title: {
          display: true,
          text: `Performance: ${attributeOptions.find((opt) => opt.value === selectedAttribute)?.label}`,
        },
      },
      scales: {
        x: { title: { display: true, text: 'Match Date' } },
        y: {
          title: {
            display: true,
            text: attributeOptions.find((opt) => opt.value === selectedAttribute)?.label,
          },
        },
      },
    };

    chartSection = (
      <div style={{ marginTop: '30px' }}>
        <div style={{ marginBottom: '20px', display: 'flex', alignItems: 'center' }}>
          {/* Comparison Selector */}
          <div style={{ marginRight: '20px' }}>
            <label htmlFor="comparisonSelect"><strong>Comparison:</strong> </label>
            <select
              id="comparisonSelect"
              value={comparison}
              onChange={(e) => setComparison(e.target.value)}
              style={{ padding: '5px', marginLeft: '10px' }}
            >
              {comparisonOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Measure Type Selector */}
          <div style={{ marginRight: '20px' }}>
            <label htmlFor="measureSelect"><strong>Measure Type:</strong> </label>
            <select
              id="measureSelect"
              value={measureType}
              onChange={(e) => setMeasureType(e.target.value)}
              style={{ padding: '5px', marginLeft: '10px' }}
            >
              {measureOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
          {/* Attribute Selector */}
          <div>
            <label htmlFor="attributeSelect"><strong>Attribute:</strong> </label>
            <select
              id="attributeSelect"
              value={selectedAttribute}
              onChange={(e) => setSelectedAttribute(e.target.value)}
              style={{ padding: '5px', marginLeft: '10px' }}
            >
              {attributeOptions.map((option) => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
          </div>
        </div>
        <Line data={chartData} options={options} />
      </div>
    );
  }

  // Build the player info card (display image only if available)
  let playerCard = null;
  if (playerPhysicalData && playerPhysicalData.player) {
    const apiPlayer = playerPhysicalData.player;
    playerCard = (
      <div style={cardStyle}>
        {apiPlayer.image ? (
          <img src={apiPlayer.image} alt={apiPlayer.shortName} style={cardImageStyle} />
        ) : null}
        <div style={cardInfoStyle}>
          <h3>{apiPlayer.shortName}</h3>
          <p>
            <strong>Full Name:</strong> {apiPlayer.firstName} {apiPlayer.middleName} {apiPlayer.lastName} <br />
            <strong>Current Team:</strong> {apiPlayer.currentTeam} <br />
            <strong>Position:</strong> {apiPlayer.position} <br />
            <strong>Birth Date:</strong> {new Date(apiPlayer.birthDate).toLocaleDateString()} <br />
            ({new Date().getFullYear() - new Date(apiPlayer.birthDate).getFullYear() - 
            (new Date().setFullYear(0) < new Date(apiPlayer.birthDate).setFullYear(0) ? 1 : 0)} years old) <br />
            <strong>Birth Country:</strong> {apiPlayer.birthArea} <br />
            <strong>Passport Country:</strong> {apiPlayer.passportArea} <br />
            <strong>Height:</strong> {apiPlayer.height} cm <br />
            <strong>Weight:</strong> {apiPlayer.weight} kg <br />
            <strong>Foot:</strong> {apiPlayer.foot.charAt(0).toUpperCase() + apiPlayer.foot.slice(1)}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div style={containerStyle}>
      <h2>Player Physical Data Search</h2>
      <div style={{ position: 'relative' }}>
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Search players..."
          style={inputStyle}
        />
        {searchQuery.trim().length >= 3 && filteredPlayers.length > 0 && (
          <div style={dropdownStyle}>
            {filteredPlayers.map((player) => (
              <div
                key={player.wyId}
                style={dropdownItemStyle}
                onClick={() => handleSelectPlayer(player)}
              >
                {player.shortName} ({player.current_team})
              </div>
            ))}
          </div>
        )}
      </div>
      {selectedPlayer && (
        <div style={selectedStyle}>
          {loadingPhysicalData ? (
            <p>Loading physical data...</p>
          ) : playerPhysicalData && playerPhysicalData.error ? (
            <p>{playerPhysicalData.error}</p>
          ) : playerPhysicalData ? (
            <>
              {playerCard}
              {chartSection}
            </>
          ) : null}
        </div>
      )}
    </div>
  );
};

export default PlayerSearchAutocomplete;
